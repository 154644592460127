/**
 * This is the starting point of your application.
 * oneAudi OS and Feature Hub Apps will use this file to bootstrap the app.
 */
import React, { useEffect, useState } from 'react';
import { AudiPlatformProvider } from '@audi/audi-ui-react';
import { useContent } from '@oneaudi/feature-app-utils';
import { I18nContextProvider } from './i18n';
import { AsyncFeatureAppProps, InitialState } from './FeatureAppTypes';
import { Module } from './components/svg-scroll-zoom-module/Module';
import { Context } from './context';
import { createInitialState } from './utils/stateUtils';
import { Content } from './types';

// eslint-disable-next-line react/function-component-definition
const FeatureApp: React.FunctionComponent<AsyncFeatureAppProps> = ({
  asyncStateHolder,
}: AsyncFeatureAppProps) => {
  const [state, setState] = useState<InitialState | undefined>(
    typeof asyncStateHolder === 'object' ? asyncStateHolder : undefined,
  );
  /* eslint-disable  @typescript-eslint/no-explicit-any */
  /* eslint-disable @typescript-eslint/no-unsafe-assignment */
  const { vueFormatterService, localeService, i18nService, logger } = React.useContext(Context);

  const content = useContent<Content>();

  useEffect(() => {
    let mounted = true;
    if (state && content) {
      /*  eslint-disable-next-line @typescript-eslint/no-floating-promises */
      createInitialState(content, vueFormatterService, localeService, i18nService, logger).then(
        (initialState) => {
          if (mounted) setState(initialState);
        },
      );
    }
    return () => {
      mounted = false;
    };
  }, [content]);

  useEffect(() => {
    if (typeof asyncStateHolder === 'function') {
      logger?.info('SSR did not serialize any state');
      /*  eslint-disable-next-line @typescript-eslint/no-floating-promises */
      asyncStateHolder().then(setState);
    } else {
      logger?.info('SSR serialized state', asyncStateHolder);
    }
  }, []);

  if (!state) {
    return null;
  }
  return (
    <I18nContextProvider i18nMessages={state.i18nMessages}>
      <AudiPlatformProvider>
        <Module {...state} />
      </AudiPlatformProvider>
    </I18nContextProvider>
  );
};

export default FeatureApp;
