import { Asset, ContentFragment } from '@oneaudi/falcon-tools';
import { presets } from '../presets';
import {
  AssetType,
  Content,
  CustomMask,
  HeadlessContent,
  ImageAsset,
  MaskType,
  PresetMask,
  PresetTypes,
  ThemeType,
  VideoAsset,
} from '../types';

export const getMask = (content: ContentFragment) => {
  if (content.fields.maskType === MaskType.maskPreset) {
    if (content.fields.text === PresetTypes.GT) return presets[PresetTypes.GT];
    if (content.fields.text === PresetTypes.RS) return presets[PresetTypes.RS];
    if (content.fields.text === PresetTypes.RSQ8) return presets[PresetTypes.RSQ8];
    return presets[PresetTypes.SONOS];
  }
  return {
    offsetWidth: content.fields.offsetWidth,
    offsetHeight: content.fields.offsetHeight,
    maskType: content.fields.maskType,
    text: content.fields.text,
  } as unknown as CustomMask;
};

export const getAsset = (content: ContentFragment): ImageAsset | VideoAsset => {
  if (content.fields.assetType === AssetType.image) {
    return {
      assetType: content.fields.assetType,
      url: {
        assetValue: (content.fields.url as unknown as Asset).path,
      },
      urlMobile: {
        assetValue: (content.fields.urlMobile as unknown as Asset).path,
      },
      alt: content.fields.alt,
    } as unknown as ImageAsset;
  }
  return {
    assetType: content.fields.assetType,
    sourceDefault: { assetValue: (content.fields.sourceDefault as unknown as Asset).path },
    sourceMobile: { assetValue: (content.fields.sourceMobile as unknown as Asset).path },
    loop: content.fields.loop,
    playMode: content.fields.playMode,
    thresholdValue: content.fields.thresholdValue,
    title: content.fields.title,
    imageFallback: { assetValue: (content.fields.imageFallback as unknown as Asset).path },
    imageFallbackMobile: {
      assetValue: (content.fields.imageFallbackMobile as unknown as Asset).path,
    },
  } as unknown as VideoAsset;
};

export const mapHeadlessContent = (content: Content | HeadlessContent): Content => {
  if ('__type' in content && content.__type === 'aem-headless') {
    const normalizedContent: Content = {
      theme: ThemeType.light === content.fields.theme ? ThemeType.light : ThemeType.dark,
      content: {
        mask:
          content.fields.content_mask.fields.maskType === MaskType.maskCustom
            ? getMask(content.fields.content_mask)
            : (getMask(content.fields.content_mask) as PresetMask),
        showGuide: content.fields.content_showGuide,
        asset: getAsset(content.fields.content_asset),
      },
      themeWltp: content.fields.themeWltp,
      wltp: content.fields.wltp,
      additionalText: content.fields.additionalText,
    };
    return normalizedContent;
  }
  return content as Content;
};
