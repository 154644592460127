/* eslint-disable react/self-closing-comp */
import React, { useState, useRef, useEffect, useContext } from 'react';
import styled from 'styled-components';
import { AssetHelper } from '../../helper/assetHelper';
import { VideoAsset, PlayMode } from '../../types';
import { useViewbox } from '../../utils/use-viewbox';
import { useIntersection } from '../../utils/use-intersection';
import { isPortrait } from '../../utils/get-orientation';
import { useBreakpoint } from '../../utils/use-breakpoint';
import { Context } from '../../context';

const StyledVideo = styled.video`
  z-index: -1;
  position: absolute;
  width: 100%;
  min-height: 100%;
  object-fit: cover;
`;

const VideoContainer = styled.div`
  height: 100%;
`;

export interface VideoProps {
  position: number;
  testId: string;
  asset: VideoAsset;
}

export const Video = ({ position, testId, asset }: VideoProps) => {
  const { logger } = useContext(Context);
  const [playing, setPlaying] = useState(false);
  const videoPlayer = useRef<HTMLVideoElement>(null);
  const videoContainer = useRef<HTMLDivElement>(null);

  // TODO: we should handle the correct video asset url in the asset helper
  // - we get the source string from editor (type: video/image), (asset source: local uri or full url)
  // - we have a asset source resolver that determines breakpoint (for size) and orientation
  // - Component consumes result of an resolver.

  const [source, setSource] = useState<string>();
  const [fallbackUrl, setFallbackUrl] = useState<string>();

  const {
    sourceDefault,
    sourceMobile,
    title,
    playMode,
    loop,
    thresholdValue,
    imageFallback,
    imageFallbackMobile,
  } = asset;

  const { orientation } = useViewbox();

  const entry = useIntersection({
    node: videoContainer,
    rootMargin: '0% 0% -10% 0%',
  });

  const breakpoint = useBreakpoint();

  useEffect(() => {
    if (isPortrait()) {
      setFallbackUrl(`${imageFallbackMobile.assetValue}?width=${breakpoint}`);
    }
    setFallbackUrl(`${imageFallback.assetValue}?width=${breakpoint}`);
  }, [orientation, breakpoint]);

  useEffect(() => {
    if (videoPlayer.current) {
      videoPlayer.current.load();
      setPlaying(false);
    }
  }, [source]);

  useEffect(() => {
    if (videoPlayer.current && source && !playing) {
      if (playMode === PlayMode.autoPlay || position * 100 >= thresholdValue) {
        videoPlayer.current.play();
        setPlaying(true);
      }
    }
  }, [position, source, playing]);

  useEffect(() => {
    if (!source && entry?.isIntersecting) {
      setSource(
        AssetHelper.getVideoUrl(
          isPortrait() ? sourceMobile?.assetValue : sourceDefault?.assetValue,
          logger,
        ),
      );
    }
  }, [entry, source, orientation]);

  useEffect(() => {
    if (entry?.isIntersecting === false && videoPlayer.current) {
      videoPlayer.current.pause();
      setPlaying(false);
    }
  }, [entry]);

  return (
    <VideoContainer ref={videoContainer}>
      <StyledVideo
        key={source}
        ref={videoPlayer}
        loop={loop}
        muted
        playsInline
        data-testid={testId}
        title={title}
        poster={fallbackUrl}
      >
        <source src={source} />
      </StyledVideo>
    </VideoContainer>
  );
};
