import React, { useState } from 'react';
import styled from 'styled-components';

import { ThemeProvider } from '@audi/audi-ui-react';
import { useInViewEffect } from '@oneaudi/feature-app-utils';

import { AssetType, ImageAsset, VideoAsset } from '../../types';

import { ScrollBox } from './scroll-box';
import { Canvas } from './canvas';
import { Image } from '../image';
import { Video } from './video';
import { LegalComponent } from './wltp';

import { useTrackingManager } from '../../context/use-tracking-manager';
import { InitialState } from '../../FeatureAppTypes';

const Background = styled.div<{ color: string }>`
  background-color: ${({ color }) => color};
`;

// eslint-disable-next-line react/function-component-definition
export const Module: React.FC<InitialState> = ({
  theme,
  content,
  themeWltp,
  wltp,
  additionalText,
}: InitialState) => {
  const trackingManager = useTrackingManager();

  const [position, setPosition] = useState(0);

  const {
    asset,
    asset: { assetType },
  } = content;

  // only trigger tracking events once
  const [alreadyTracked, setAlreadyTracked] = React.useState(false);
  const [endOfAnimationReached, setEndOfAnimationReached] = React.useState(false);

  // initial tracking
  React.useEffect(() => {
    trackingManager.loaded();
  }, []);

  // in view tracking
  useInViewEffect(() => {
    if (!alreadyTracked) {
      setAlreadyTracked(true);
      trackingManager.impression(false, assetType);
    }
  });

  // end of module reached tracking
  React.useEffect(() => {
    if (endOfAnimationReached) return;
    setEndOfAnimationReached(trackingManager.reachEndOfModule(assetType, position));
  }, [position]);
  return (
    <ThemeProvider theme={theme}>
      <Background color={theme.colors.ui.inverted}>
        <ScrollBox outerHeight="250vh" innerHeight="100vh" onScroll={setPosition}>
          <Canvas position={position} theme={theme} {...content}>
            {assetType === AssetType.image && <Image testId="image" asset={asset as ImageAsset} />}
            {assetType === AssetType.video && (
              <Video position={position} testId="video" asset={asset as VideoAsset} />
            )}
          </Canvas>
        </ScrollBox>
      </Background>
      <LegalComponent theme={themeWltp} additionalText={additionalText} wltp={wltp} />
    </ThemeProvider>
  );
};
